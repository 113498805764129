.button__buy {
  background: linear-gradient(140deg, $red 50%, $purple 90%);
  color: $white;
  margin: 30px 0;
  display: inline-flex;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 50px;
  border: 3px solid $purple;

  &:hover {
    background: $purple;
  }

  img {
    width: 10px;
    margin-left: 20px;
  }
}

.button__buy-disappear {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 30px;
  align-items: center;
  background: linear-gradient(140deg, $red 50%, $purple 90%);
  color: $white;
  margin: 30px 0;
  letter-spacing: 5px;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  padding: 15px 40px;
  border-radius: 50px;
  border: 3px solid $purple;
  font-size: 20px;

  &:hover {
    background: $purple;
  }

  span {
    font-size: 18px;
    letter-spacing: 1px;
  }

  img {
    width: 20px;
  }
}
