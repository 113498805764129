.stylist {
  background-color: $purple;
  padding: 50px 0;
  margin-top: 50px;

  @include breakpoint($mobile) {
    margin-top: 0;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 200px;

    @include breakpoint($tablet) {
      gap: 100px;
    }

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }

  &__text {
    color: $white;

    &--big {
      font-size: 38px;
      font-weight: 900;
      line-height: 1;
    }
  }

  &__image {
    max-width: 80%;
    margin-bottom: -200px;

    @include breakpoint($tablet) {
      max-width: 100%;
    }
  }
}
