.tiles {
  margin-top: 50px;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @include breakpoint($tablet) {
      grid-template-columns: 1fr;
      gap: 70px;
    }
  }

  &__tile {
    background-color: $purple;
    color: $white;
    padding: 80px 40px 40px;
    position: relative;
    border-radius: 20px;

    span {
      font-weight: 900;
    }
  }

  &__icon {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: -50px;
    right: 50px
  }

  &__text {
    margin-bottom: 30px;
  }
}
