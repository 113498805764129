html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
html {
  background-color: $white;
  color: $text;
  scroll-behavior: smooth;
  font-family: 'Anek Latin', sans-serif;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Abril Fatface', serif;
}

p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

img {
  display: block;
  height: auto;
  width: 100%;
}

ul,
li {
  margin: 0;
  padding: 0;
}

.mark {
  font-weight: 900;
  background-image: linear-gradient(to bottom,$yellow 0%,$yellow 10%);
  background-repeat: no-repeat;
  background-size: 100% 0.7em;
  background-position: 0 120%;
}

.mark-white {
  font-weight: 900;
  background-image: linear-gradient(to bottom,$white 0%,$white 10%);
  background-repeat: no-repeat;
  background-size: 100% 0.7em;
  background-position: 0 120%;
}

.yellow {
  color: $yellow;
}

.bold {
  font-weight: 900;
}

.button-right {
  display: grid;
  justify-content: end !important;
}
