@import 'utilities/variables';
@import 'utilities/global';
@import 'utilities/normalize';
@import 'utilities/breakpoints';
@import 'utilities/container';
@import 'utilities/button';

@import 'includes/nav';
@import 'includes/footer';

@import 'homepage/header';
@import 'homepage/text';
@import 'homepage/video';
@import 'homepage/for';
@import 'homepage/mobile-image';
@import 'homepage/stylist';
@import 'homepage/quote';
@import 'homepage/quote-video';
@import 'homepage/career';
@import 'homepage/tiles';
@import 'homepage/author';
@import 'homepage/osa';
@import 'homepage/package';
@import 'homepage/standard';
@import 'homepage/standard-mockup';
@import 'homepage/premium';
@import 'homepage/box';
@import 'homepage/buy';
@import 'homepage/short';
@import 'homepage/testimonials';
@import 'homepage/faq';
