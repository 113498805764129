.career {
  margin-top: 100px;
  padding: 40px 0;
  background-color: #ece2f3;

  &__heading {
    font-size: 36px;
    line-height: 1.5;
    font-family: $ftext;
    margin-bottom: 30px;

    @include breakpoint($mobile) {
      font-size: 25px;
    }
  }
}
