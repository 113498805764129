.header {
  background: $yellow;

  @include breakpoint($mobile) {
    background: linear-gradient(180deg, $yellow 80%, $white 80%);
    overflow: hidden;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 30px 0;

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      padding: 15px 0;
    }
  }

  &__text {
    max-width: 400px;
    margin: 0 auto;
    font-size: 35px;

    @include breakpoint($tablet) {
      font-size: 27px;
    }
  }

  &__text-font {
   font-family: 'Anek Latin', sans-serif;
   font-size: 80px;
   font-weight: 900;
   line-height: 1;
   margin-left: 40px;

   @include breakpoint($tablet) {
    font-size: 50px;
   }
 }

  &__text-color {
    color: $red;
  }

  &__photo {
    margin-top: -40px;
    margin-bottom: -80px;

    @include breakpoint($mobile) {
      margin: 0 -10% 20px;
      width: 120%;
    }
  }

  &__button {
    display: grid;
    justify-content: end;
    margin-right: 130px;

    @include breakpoint($mobile) {
      margin-right: 50px;
    }
  }

  .button__buy {
    padding: 25px 60px;
    font-size: 20px;

    @include breakpoint($tablet) {
      padding: 15px 40px;
      font-size: 16px;
    }
  }
}
