.short {
  margin-top: 50px;
  padding-bottom: 30px;
  background: linear-gradient(180deg, $white 30%, $yellow 30%);
  margin-bottom: 20px;

  @include breakpoint($mobile) {
    background: $yellow
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &__mockup {
    @include breakpoint($mobile) {
      margin-top: -50px;
    }
  }

  &__text {
    font-size: 24px;
    margin-top: 30px;

    @include breakpoint($tablet) {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
