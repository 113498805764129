.box {
  margin-top: -50px;
  z-index: 1;
  position: relative;

  @include breakpoint($mobile) {
    margin-top: -90px;
  }

  &__wrapper {
    background-color: $purple;
    padding: 30px 200px;
    display: grid;
    grid-template-columns: auto 1fr;
    border-radius: 20px;
    align-items: center;
    gap: 50px;
    color: $white;

    @include breakpoint($tablet) {
      padding: 30px 100px;
    }

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      padding: 30px;
      gap: 0;
    }
  }

  &__icon {
    max-width: 70px;

    @include breakpoint($mobile) {
      display: none;
    }
  }

  &__phone {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  &__mail {
    text-decoration: underline;
    color: $yellow;
  }
}
