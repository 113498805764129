.video {
  display: grid;
  margin-top: 80px;
  align-items: end;

  @include breakpoint($tablet) {
    margin-top: 50px;
  }

  &__left {
    justify-content: start;
    display: grid;

    @include breakpoint($mobile) {
      justify-content: end;
    }
  }
}
