.testimonials {
  background-color: $yellow;
  padding: 40px 0;

  &__heading {
    font-family: $ftext;
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: normal;

    @include breakpoint($mobile) {
      font-weight: 900;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @include breakpoint($tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint($mobile) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    display: grid;
    gap: 20px;
    align-content: start;
  }

  &__more {
    color: $red;
    text-decoration: none;
    letter-spacing: 3px;
    font-weight: 900;
    display: block;
    margin-top: 30px;
    text-align: right;
  }
}
