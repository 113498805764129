.faq {
  margin-top: 50px;
  margin-bottom: 80px;

  &__heading {
    font-size: 40px;
    font-family: $ftext;
    margin-bottom: 30px;

    span {
      font-size: 80px;

      @include breakpoint($mobile) {
        font-size: 50px;
      }
    }

    @include breakpoint($mobile) {
      font-size: 25px;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  &__tab {
    color: white;
    overflow: hidden;
    margin: 0 auto;

    @media (max-width: 900px) {
      width: 100%;
    }
  }

  &__tab-label {
    display: grid;
    grid-template-columns: 1fr auto;
    color: $text;
    font-weight: bold;
    gap: 30px;
    align-items: center;
    padding: 25px 20px;
    cursor: pointer;
    transition: all .35s;
    border-bottom: 2px solid $purple;

    @media (max-width: 900px) {
      padding: 15px 10px;
    }

    &:after {
      content: "\276F";
      width: 20px;
      height: 20px;
      text-align: center;
      transition: all .35s;
      color: $black;
    }
  }

  &__tab-content {
    max-height: 0;
    padding: 0 1em;
    color: $text;
    background: $white;
    transition: all .35s;
  }

  &__input:checked + .faq__tab-label {
    background-color: $yellow;

    &:after {
      transform: rotate(90deg);
    }
  }

  &__input:checked ~ .faq__tab-content {
    max-height: 100vh;
    padding: 20px 1em 30px 1em;
  }
}
