.quote-video {
  margin-top: 80px;

  @include breakpoint($tablet) {
    margin-top: 30px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;

    @include breakpoint($tablet) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }

  &__border {
    border: 3px solid $purple;
    border-radius: 20px;
    padding: 30px 30px 60px;
    position: relative;
    max-width: 80%;
    margin-left: 100px;

    @include breakpoint($tablet) {
      margin-left: 0;
      max-width: 100%;
    }
  }

  &__heading {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 16px;
  }

  &__photo {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    right: 50px;
  }
}
