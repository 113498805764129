.premium {
  background-color: #dfdcf8;
  margin-top: 170px;
  position: relative;
  padding-top: 200px;
  padding-bottom: 120px;

  @include breakpoint($mobile) {
    padding-top: 100px;
  }

  &__thumbnail {
    max-width: 400px;
    position: absolute;
    top: -100px;

    @include breakpoint($mobile) {
      max-width: 300px;
      width: 90%;
    }
  }

  &__heading {
    font-family: $ftext;
    font-weight: 900;
    font-size: 30px;

    span {
      font-size: 45px;

      @include breakpoint($mobile) {
        font-size: 30px;
      }
    }

    @include breakpoint($mobile) {
      font-size: 25px;
    }
  }

  &__text {
    letter-spacing: 2px;
    margin-top: 30px;
    font-size: 24px;
  }

  &__item {
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 50px;
    margin-top: 30px;

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &__instructor {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    align-items: center;
    margin-bottom: 30px;

    @include breakpoint($mobile) {
      grid-template-columns: 100px 1fr;
    }
  }

  &__photo {
    max-width: 150px;
    border-radius: 50%;
  }

  &__instructor-text {
    font-size: 24px;
    font-weight: bold;

    @include breakpoint($mobile) {
      font-size: 20px;
    }
  }

  &__arrow {
    display: block;
    width: 20px;

    @include breakpoint($mobile) {
      width: 15px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: bold;

    @include breakpoint($mobile) {
      font-size: 18px;
      margin: 10px 0;
      gap: 20px;
    }
  }

  &__list--dark {
    background-color: #b7b3e9;
  }

  &__list--light {
    background-color: #ccc9f0;
  }

  &__item-mockup {
    margin-top: 150px;
    margin-bottom: 20px;

    @include breakpoint($mobile) {
      margin-top: 0;
    }
  }
}
