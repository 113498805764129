.text {
  margin-top: 100px;

  @include breakpoint($mobile) {
    margin-top: 25px;
  }

  &__heading {
    font-size: 36px;
    font-family: $ftext;
    font-weight: normal;

    span {
      font-weight: 900;
    }

    @include breakpoint($mobile) {
      font-size: 28px;
    }
  }

  &__text {
    margin-top: 20px;
    font-size: 24px;

    @include breakpoint($mobile) {
      font-size: 18px;
    }
  }
}
