.buy {
  padding: 70px 0 50px;

  &__wrapper {
    margin: 0 auto;
    max-width: 600px;
    gap: 20px;
    align-items: center;

    @include breakpoint($tablet) {
      max-width: 100%;
    }

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &__standard {
    background-color: #f4dce1;
    border-top: 20px solid #e5a8c4;
    padding: 30px 60px;

    @include breakpoint($mobile) {
      padding: 30px;
    }
  }

  &__header {
    font-weight: 900;
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
  }

  &__dots {
    border-top: 1px dotted $text;
  }

  &__list {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin: 15px 0;
  }

  &__arrow {
    width: 30px;
  }

  &__price {
    text-align: center;
    color: $red;
    font-weight: 900;
    font-size: 48px;
    margin-top: 30px;
    line-height: 1;

    @include breakpoint($mobile) {
      font-size: 32px;
    }
  }

  &__price-before {
    font-weight: 900;
    text-align: center;
    margin-top: 20px;
    font-size: 25px;
  }

  &__omnibus {
    font-size: 14px;
    text-align: center;
  }

  &__payment {
    color: $red;
    text-align: center;
  }

  &__disclaimer {
    font-size: 16px;
    margin-top: 20px;
  }

  &__buy {
    display: grid;
    justify-content: center;
  }

  &__premium {
    background-color: #dfdcf8;
    border-top: 20px solid #b9b1ec;
    padding: 30px 60px;

    @include breakpoint($mobile) {
      padding: 30px;
    }
  }

  &__counter {
    margin-bottom: 40px;
    font-weight: bold;
  }

  &__counter-heading {
    text-align: center;
    margin-bottom: 10px;
    color: $red;
    font-weight: 900;
    font-size: 42px;
    font-family: $ftext;
  }

  &__strike {
    text-decoration: line-through;
    font-size: 56px;
    margin-right: 10px;

    @include breakpoint($mobile) {
      font-size: 40px;
    }
  }
}

.newsletter {
  margin-top: 50px;

  &__container {
    background-color: #e3dff8;
    text-align: center;
    padding: 50px;
    max-width: 800px;
    margin: 0 auto;

    @include breakpoint($mobile) {
      padding: 30px 5px;
    }
  }

  &__heading {
    color: $black;
  }

  &__form {
    max-width: 500px;
    margin: 0 auto;
  }
}

#mlb2-5826508.ml-form-embedContainer .ml-form-embedWrapper .ml-form-embedBody .ml-form-checkboxRow label p {
  color: $white !important;
}