.author {
  margin-top: 80px;

  &__heading {
    font-size: 48px;
    font-family: $ftext;
    margin-bottom: 30px;

    @include breakpoint($mobile) {
      font-size: 36px;
    }
  }

  &__media {
    font-size: 30px;
    margin-top: 50px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 3fr 6fr;
    align-items: center;

    @include breakpoint($tablet) {
      grid-template-columns: 1fr;
    }
  }

  &__media-list {
    display: flex;
    gap: 50px;

    @include breakpoint($tablet) {
      display: grid;
      margin-top: 20px;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
    }
  }

  &__logo {
    max-width: 80px;
    object-fit: contain;
  }
}
