$black: #1b172c;
$white: #ffffff;
$purple: #68317b;
$yellow: #fdff39;
$lawenda: #ece2f3;
$red: #f60023;
$text: #5f1b73;

$ftext: 'Anek Latin', sans-serif;
$fheading: 'Abril Fatface', serif;
