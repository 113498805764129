.quote {
  margin-top: 100px;

  @include breakpoint($mobile) {
    margin-top: 190px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;

    @include breakpoint($tablet) {
      gap: 50px;
    }

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }

  &__left {
    border-left: 5px solid $yellow;
    align-self: self-end;
    padding-left: 20px;
  }

  &__quote {
    font-size: 30px;
    font-family: $fheading;

    @include breakpoint($tablet) {
      font-size: 25px;
    }
  }

  &__author {
    font-size: 16px;
    margin-top: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }

  &__right {
    align-self: self-end;
    justify-content: end;
    display: grid;

    @include breakpoint($mobile) {
      align-self: flex-start;
    }
  }
}
