.osa {
  margin-top: 80px;
  padding: 40px 0;
  background-color: $yellow;

  @include breakpoint($mobile) {
    margin-top: 40px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  &__left {
    border-left: 10px solid $white;
    padding-left: 25px;
    align-self: self-start;

    @include breakpoint($mobile) {
      order: 2;
    }
  }

  &__name {
    margin-top: 20px;
    text-align: right;
    font-weight: 900;
    font-size: 20px;
  }

  &__photo {
    max-width: 80%;

    @include breakpoint($mobile) {
      max-width: 100%;
    }
  }
}
