.standard {
  background-color: #f9dbe2;
  margin-top: 150px;
  position: relative;
  padding-top: 220px;
  padding-bottom: 50px;

  @include breakpoint($mobile) {
    padding-top: 120px;
  }

  &__thumbnail {
    max-width: 400px;
    position: absolute;
    top: -100px;

    @include breakpoint($mobile) {
      max-width: 300px;
      width: 90%;
    }
  }

  &__heading {
    font-family: $ftext;
    font-weight: 900;
    font-size: 30px;

    span {
      font-size: 45px;

      @include breakpoint($mobile) {
        font-size: 30px;
      }
    }

    @include breakpoint($mobile) {
      font-size: 25px;
    }
  }

  &__text {
    letter-spacing: 2px;
    margin-top: 20px;
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__arrow {
    display: block;
    width: 20px;

    @include breakpoint($mobile) {
      width: 15px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 22px;

    @include breakpoint($mobile) {
      font-size: 18px;
      margin: 10px 0;
      gap: 20px;
    }
  }

  &__list--dark {
    background-color: #e5a8c4;
  }

  &__list--light {
    background-color: #eec7d9;
  }
}
