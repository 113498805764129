.nav {
  background-color: $yellow;
  padding: 10px 0;

  &__logo {
    max-width: 200px;

    @include breakpoint($mobile) {
      max-width: 150px;
    }
  }
}
