.standard-mockup {
  margin-top: 80px;

  @include breakpoint($mobile) {
    margin-top: 60px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 150px;
    align-items: center;

    @include breakpoint($tablet) {
      gap: 50px;
    }

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
    }
  }

  &__tile {
    border: 5px solid #e6a9c4;
    padding: 50px 40px 40px;
    position: relative;
    border-radius: 20px;

    span {
      font-weight: 900;
      font-size: 30px;

      @include breakpoint($mobile) {
        font-size: 25px;
      }
    }

    @include breakpoint($mobile) {
      padding: 50px 20px 40px;
    }
  }

  &__icon {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    top: -50px;
    right: 50px
  }

  &__button {
    justify-content: start;
    display: grid;
  }
}
