.for {
  margin-top: 80px;
  margin-bottom: 80px;

  @include breakpoint($mobile) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__heading {
    font-size: 48px;
    font-family: $ftext;
    margin-bottom: 30px;

    @include breakpoint($mobile) {
      font-size: 35px;
      margin-bottom: 15px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 200px;

    @include breakpoint($tablet) {
      gap: 100px;
    }

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 30px;
    margin-top: 30px;
    font-size: 18px;

    @include breakpoint($mobile) {
      gap: 20px;
    }
  }

  &__arrow {
    width: 50px;
    margin-top: -5px;

    @include breakpoint($mobile) {
      width: 35px;
    }
  }
}
