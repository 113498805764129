.package {
  padding: 50px 0 100px;

  @include breakpoint($mobile) {
    padding: 50px 0;
  }

  &__heading {
    font-size: 36px;
    line-height: 1.5;
    font-family: $ftext;
    margin-bottom: 30px;
    text-align: center;

    @include breakpoint($mobile) {
      font-size: 25px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;

    @include breakpoint($mobile) {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }

  &__item {
    position: relative;
    background-color: #e4e0f9;
    padding-bottom: 30px;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__text {
    z-index: 1;
    position: relative;
    margin-top: 90px;
    margin-left: 45%;
    margin-right: 5%;

    @include breakpoint($mobile) {
      margin-left: 40%;
    }
  }

  &__bonus {
    font-size: 30px;
    font-weight: bold;

    @include breakpoint($mobile) {
      font-size: 25px;
    }
  }

  &__files {
    font-size: 22px;
    font-weight: 900;

    @include breakpoint($mobile) {
      font-size: 20px;
    }
  }

  &__checklists {
    font-size: 18px;

    @include breakpoint($mobile) {
      font-size: 16px;
    }
  }

  &__value {
    font-weight: bold;

    span {
      color: $red;
      font-size: 25px;
      font-weight: 900;
    }
  }
}